import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRepository } from '../../store/app.repository';
import { ApplicationRoutes } from '../settings/common.settings';
import { AppConfiguration } from '../models/app-configuration.model';
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpStatusCode,
  HttpClient,
} from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StartModel } from '../models/start.model';
import { NextModel } from '../../pages/landing/models/next.model';
import { OutputKeysRepository } from '../store/output-keys/output-keys.repository';
import {
  OutputPropertyItemModel,
  OutputPropertyKeyValueModel,
} from '../models/output-keys.model';
import { v4 as uuidv4 } from 'uuid';
import { DocumentCaptureRepository } from '@root/pages/blocks/document-capture/store/document-capture.repository';
import { LayoutModel } from '../models/layout.model';
import { DataEncryptionService } from './encryption/dataencryption.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    private appRepository: AppRepository,
    private outputKeysRepository: OutputKeysRepository,
    private documentCaptureRepository: DocumentCaptureRepository,
    private dataEncryptionService: DataEncryptionService,
    private route: Router
  ) { }

  updateStepIdAndStepDefinitionInAppConfiguration(
    stepId: number,
    stepDefinition: string
  ) {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      this.appRepository.updateAppConfiguration({
        tenantId: appConfiguration.tenantId,
        blockId: appConfiguration.blockId,
        stepId: stepId,
        stepDefinition: stepDefinition,
        instanceId: appConfiguration.instanceId,
        applicationId: appConfiguration.applicationId,
        flowName: appConfiguration.flowName,
        instanceHash: appConfiguration.instanceHash,
        flowIdentifier: appConfiguration.flowIdentifier,
        flowInstanceId: appConfiguration.flowInstanceId,
        defaultLanguageId: appConfiguration.defaultLanguageId,
        chatEnabled: appConfiguration.chatEnabled,
      });
    } else {
      this.route.navigate([ApplicationRoutes.AreYouLost]);
    }
  }

  updateStepIdInAppConfiguration(stepId: number) {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      this.appRepository.updateAppConfiguration({
        tenantId: appConfiguration.tenantId,
        blockId: appConfiguration.blockId,
        stepId: stepId,
        stepDefinition: appConfiguration.stepDefinition,
        instanceId: appConfiguration.instanceId,
        applicationId: appConfiguration.applicationId,
        flowName: appConfiguration.flowName,
        flowIdentifier: appConfiguration.flowIdentifier,
        instanceHash: appConfiguration.instanceHash,
        flowInstanceId: appConfiguration.flowInstanceId,
        defaultLanguageId: appConfiguration.defaultLanguageId,
        chatEnabled: appConfiguration.chatEnabled,
      });
    } else {
      this.route.navigate([ApplicationRoutes.AreYouLost]);
    }
  }

  callStartApi(
    instanceHash: string,
    flowInstanceId?: string,
    stepId?: number,
    instanceId?: string,
    callBackFun?: () => void
  ) {
    let httpHeaders;
    if (flowInstanceId) {
      httpHeaders = {
        headers: new HttpHeaders({
          'X-Flow-Instance-Id': flowInstanceId,
          InterceptorSkipConfigurationHeader: '',
        }),
      };
    } else {
      httpHeaders = {
        headers: new HttpHeaders({
          InterceptorSkipConfigurationHeader: '',
        }),
      };
    }
    this.http
      .get<StartModel>(
        `${environment.assentifyEndPoint}Manager/Start/${instanceHash}`,
        httpHeaders
      )
      .pipe(
        map((response) => {
          if (callBackFun) {
            callBackFun();
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 0) {
            // A client-side or network error occurred
          } else {
            // The backend returned an unsuccessful response code.
            if (error.status === HttpStatusCode.NotFound) {
              this.route.navigate([ApplicationRoutes.AreYouLost]);
            }
            if (error.status === HttpStatusCode.InternalServerError) {
              this.route.navigate([ApplicationRoutes.AreYouLost]);
            }
          }
          // Return an observable with a user-facing error message.
          // new Error('Something bad happened; please try again later.')
          return throwError(() => {
            // empty
          });
        })
      )
      .subscribe((data: StartModel) => {
        const appConfiguration: AppConfiguration = {
          blockId: data.blockIdentifier,
          flowInstanceId: data.flowInstanceId,
          instanceId: instanceId ? instanceId : data.instanceId,
          stepId: stepId ? stepId : data.stepId,
          instanceHash: instanceHash,
          applicationId: data.applicationId,
          flowName: data.flowName,
          tenantId: data.tenantIdentifier,
          flowIdentifier: data.flowIdentifier,
          stepDefinition: 'step',
          defaultLanguageId: data.defaultLanguageId,
          chatEnabled: data.chatEnabled,
        };

        this.appRepository.updateStepMap(data.stepMap);

        if (data.customProperties)
          this.appRepository.updateCustomProperties(data.customProperties);
        else this.appRepository.updateCustomProperties([]);
        this.appRepository.updateAppConfiguration(appConfiguration);
      });
  }

  getNextStep() {
    const appConfiguration = this.appRepository.getAppConfiguration();
    if (appConfiguration) {
      if (
        appConfiguration.stepDefinition === ApplicationRoutes.DocumentCapture
      ) {
        this.uploadBulkDocuments(appConfiguration);
      } else {
        const outputKeys = this.getOutputKeys(appConfiguration);
        if (outputKeys) {
          this.appRepository.updateLayoutModel({} as LayoutModel);
          this.callNextApi(outputKeys, appConfiguration);
        }
      }
    }
  }

  callNextApi(
    outputKeys: OutputPropertyItemModel,
    appConfiguration: AppConfiguration
  ) {
    this.dataEncryptionService.getKey().subscribe((value) => {
      const endPointUrl = `${environment.assentifyEndPoint}Manager/Next`;
      const formData = new FormData();
      formData.append('stepId', appConfiguration.stepId.toString());
      for (const key in outputKeys) {
        // eslint-disable-next-line no-prototype-builtins
        if (outputKeys.hasOwnProperty(key) && outputKeys[key].value) {
          const encryptedContent = this.dataEncryptionService.encryptData(
            outputKeys[key].value.toString(),
            value
          );
          formData.append(key, encryptedContent);
        }
      }
      this.http.post<NextModel>(endPointUrl, formData).subscribe((result) => {
        if (result) {
          this.appRepository.updateStepValidation(false);
          const { stepId, stepDefinition } = result;
          this.appRepository.updateProcessedSteps({
            id: stepId,
            stepDefinition,
          });
          this.route.navigate([`block/${stepDefinition}/${stepId}`]);
        } else {
          window.sessionStorage.clear();
        }
      });
    });
  }

  getOutputKeys(
    appConfiguration: AppConfiguration
  ): OutputPropertyItemModel | undefined {
    return this.outputKeysRepository.getOutputKeysByStepId(
      appConfiguration.stepId
    );
  }

  uploadBulkDocuments(appConfiguration: AppConfiguration) {
    const documentCaptureKeyValues =
      this.documentCaptureRepository.getDocumentCaptureKeyValues()[
      appConfiguration.stepId
      ];
    if (
      documentCaptureKeyValues &&
      Object.keys(documentCaptureKeyValues).length > 0
    ) {
      const url = `${environment.blobStorage
        }/v2/Document/UploadBulk/documentcapture/${uuidv4()}`;
      const formData: FormData = new FormData();
      for (const key in documentCaptureKeyValues) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          documentCaptureKeyValues.hasOwnProperty(key) &&
          documentCaptureKeyValues[key] instanceof Blob
        ) {
          const file = documentCaptureKeyValues[key] as Blob;
          const fileType = file.type.split('/')[1];
          formData.append('files', file, `${key}.${fileType}`);
        }
      }

      formData.append('additionalValues', appConfiguration.tenantId);
      formData.append('additionalValues', appConfiguration.blockId);
      formData.append('additionalValues', appConfiguration.flowIdentifier);
      formData.append('additionalValues', appConfiguration.stepId.toString());
      formData.append('additionalValues', appConfiguration.instanceId);

      this.http
        .post(url, formData, {
          headers: new HttpHeaders({
            InterceptorShowSpinner: '',
          }),
        })
        .subscribe((data: OutputPropertyKeyValueModel) => {
          const outputProperties: OutputPropertyItemModel = {};
          for (const key in data) {
            this.outputKeysRepository.addOutputProperty(key, data[key]);
            outputProperties[key] =
              this.outputKeysRepository.generateOutputKeysValue(key, data[key]);
          }
          this.callNextApi(outputProperties, appConfiguration);
        });
      return;
    }

    this.callNextApi({}, appConfiguration);
  }
}
